<template>
  <div class="mediclist">
    <el-card class="box-card card-one" shadow="never">
      <el-form ref="form" :model="form" inline>
        <el-form-item label="驾驶人员名称" prop="driverName">
          <el-input v-model="form.driverName"></el-input>
        </el-form-item>
      </el-form>
      <div class="btns">
        <el-button type="primary" class="selectBtn" @click="select()">查询</el-button>
        <el-button type="info" class="clearBtn" @click="clearBtn()">清空</el-button>
      </div>
    </el-card>

    <el-card class="box-card card-two" shadow="never">
      <el-row class="btn-t">
        <el-button type="primary" class="exportOrders" @click="addDialogFormVisible=true">添加</el-button>
        <el-button type="primary" class="lookMap" @click="edit()">编辑</el-button>
        <el-button type="primary" class="lookMap" @click="deletetype()">删除</el-button>
      </el-row>

      <!--添加列表-->
      <!-- 添加客户弹出框 -->
      <el-dialog :visible.sync="addDialogFormVisible" class="edit-dialog" @close="addDialogClose()">
        <span
          slot="title"
          class="dialog-Title"
          style="font-size: 0.36rem;color:#0097FE;font-weight: bold;"
        >添加驾驶员信息</span>
        <el-form
          :model="addForm"
          inline
          ref="addForm"
          :label-position="labelPosition"
          label-width="130px"
          :rules="addFormRules"
        >
          <el-row style="margin-left:2rem;">
            <el-form-item label="驾驶员类型" prop="type">
              <el-select v-model="addForm.type" placeholder="请选择驾驶员类型" style="width: 6rem;">
                <el-option
                  :label="item"
                  :value="item"
                  v-for="(item,index) in personTypes"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="区域" prop="area">
              <el-cascader
                placeholder="请选择区域"
                :options="options"
                filterable
                :props="props"
                @change="ad"
                v-model="addForm.area"
              ></el-cascader>
            </el-form-item>

            <el-form-item label="所属机构" prop="companyId">
              <el-select v-model="addForm.companyId" placeholder="请选择机构" clearable>
                <el-option
                  :label="item.companyName"
                  :value="item.uuid"
                  v-for="(item, index) in companyIdlist"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>

            <!-- <el-form-item label="所属机构" prop="companyId">
              <el-select v-model="addForm.companyId" placeholder="请选择机构" style="width: 6rem;">
                <el-option :label="item.companyName" :value="item.uuid" v-for="(item,index) in companyIdlist" :key="index">{{item.companyName}}</el-option>
              </el-select>
            </el-form-item>-->
            <el-form-item label="姓名" prop="driverName">
              <el-input
                v-model="addForm.driverName"
                placeholder="请输入姓名"
                autocomplete="off"
                style="width: 6rem;"
              ></el-input>
            </el-form-item>
            <el-form-item label="证件类型" prop="idcType">
              <el-select v-model="addForm.idcType" placeholder="请选择证件类型" style="width: 6rem;">
                <el-option :label="item" :value="item" v-for="(item,index) in idtype" :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="身份证号" prop="idcNum">
              <el-input v-model="addForm.idcNum" autocomplete="off" style="width: 6rem;"></el-input>
            </el-form-item>
            <el-form-item label="从业人员驾驶证号" prop="licenseId">
              <el-input v-model="addForm.licenseId" autocomplete="off" style="width: 6rem;"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phoneNo">
              <el-input v-model="addForm.phoneNo" autocomplete="off" style="width: 6rem;"></el-input>
            </el-form-item>
            <el-form-item label="通讯地址" prop="driverContactAddress">
              <el-input
                v-model="addForm.driverContactAddress"
                autocomplete="off"
                style="width: 6rem;"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传图片" prop="photoUrl">
              <el-upload
                ref="upload"
                :action="uploadAction"
                list-type="picture-card"
                accept="image/*"
                :headers="headers"
                :limit="imgLimit"
                :file-list="productImgs"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :on-exceed="handleExceed"
                :on-error="imgUploadError"
                :on-change="handleEditChange"
                :class="{hide:hideUploadEdit}"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="addForm.photoUrl" alt />
              </el-dialog>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="addDialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addClient()">确 定</el-button>
        </div>
      </el-dialog>

      <!--修改列表-->
      <el-dialog
        :visible.sync="editDialogFormVisible"
        class="edit-dialog"
        @close="editDialogClose()"
      >
        <span slot="title" class="dialog-Title">编辑</span>
        <el-form :model="editForm" inline ref="editForm" :rules="editFormRules">
          <el-row>
            <el-form-item label="驾驶人名称" prop="driverName">
              <el-input v-model="editForm.driverName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="工号" prop="workNo">
              <el-input v-model="editForm.workNo" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="idcNum">
              <el-input v-model="editForm.idcNum" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="editForm.remark" autocomplete="off"></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="editDialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="editClient()">确 定</el-button>
        </div>
      </el-dialog>

      <el-card class="box-card tab">
        <el-table
          ref="singleTable"
          :data="rightsList"
          border
          stripe
          highlight-current-row
          @current-change="handleCurrentChange1"
        >
          <el-table-column type="index" label="#"></el-table-column>
          <!-- <el-table-column prop="memberId" label="登录用户名称" width="150px"></el-table-column>
          <el-table-column prop="companyId" label="机构名称" width="150px"></el-table-column>-->
          <el-table-column prop="driverName" label="驾驶员名称" width="150px"></el-table-column>
          <el-table-column prop="workNo" label="工号" width="150px"></el-table-column>
          <el-table-column prop="gender" label="性别" width="150px"></el-table-column>
          <el-table-column prop="birthDate" label="出生日期" width="150px"></el-table-column>
          <el-table-column prop="idcType" label="身份证类型" width="150px"></el-table-column>
          <el-table-column prop="idcNum" label="身份证号码" width="150px"></el-table-column>
          <el-table-column prop="cardNumber" label="员工卡号" width="150px"></el-table-column>
          <el-table-column prop="nationCode" label="民族" width="150px"></el-table-column>
          <el-table-column prop="nativePlace" label="籍贯" width="150px"></el-table-column>
          <el-table-column prop="maritalStatus" label="婚姻状态" width="150px">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.maritalStatus == 0">未婚</el-tag>
              <el-tag v-if="scope.row.maritalStatus == 1">已婚</el-tag>
              <el-tag v-if="scope.row.maritalStatus == 2">离异</el-tag>
              <el-tag v-if="scope.row.maritalStatus == 3">丧偶</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="degree" label="学历" width="150px"></el-table-column>
          <el-table-column prop="phoneNo" label="联系电话" width="150px"></el-table-column>
          <el-table-column prop="emergencyContact" label="紧急联系人" width="150px"></el-table-column>
          <el-table-column prop="emergencyContactPhone" label="紧急联系人电话" width="150px"></el-table-column>
          <el-table-column prop="emergencyContactAddress" label="紧急联系人联系地址" width="150px"></el-table-column>
          <el-table-column prop="health" label="健康情况" width="150px"></el-table-column>
          <el-table-column prop="polAff" label="政治面貌" width="150px"></el-table-column>
          <el-table-column prop="presentAddr" label="现居住地址" width="150px"></el-table-column>
          <el-table-column prop="initialWorkTime" label="初次上岗日期" width="150px"></el-table-column>
          <el-table-column prop="statusOfOnpost" label="在岗情况" width="150px">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.statusOfOnpost == 0">不在岗</el-tag>
              <el-tag v-if="scope.row.statusOfOnpost == 1">在岗</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="bindStatus" label="绑定状态" width="150px"></el-table-column>
          <el-table-column prop="bindCarNum" label="绑定车牌号码" width="150px"></el-table-column>
          <el-table-column prop="driverCensus" label="户口登记机关名称" width="150px"></el-table-column>
          <el-table-column prop="driverAddress" label="户口住址或长住地址" width="150px"></el-table-column>
          <el-table-column prop="driverContactAddress" label="驾驶员通信地址" width="150px"></el-table-column>
          <el-table-column prop="photoUrl" label="驾驶员照片地址" width="150px"></el-table-column>
          <el-table-column prop="licenseId" label="机动车驾驶证号" width="150px"></el-table-column>
          <el-table-column prop="licensePhotoId" label="机动车驾驶证扫描文件编号" width="150px"></el-table-column>
          <el-table-column prop="driverType" label="准驾车型" width="150px"></el-table-column>
          <el-table-column prop="getDriverLicenseDate" label="初次领取驾驶证日期" width="150px"></el-table-column>
          <el-table-column prop="driverLicenseOff" label="驾驶证有效期限止" width="150px"></el-table-column>
          <el-table-column prop="driverLicenseOn" label="驾驶证有效期限起" width="150px"></el-table-column>
          <el-table-column prop="certificateNo" label="驾驶员资格证号" width="150px"></el-table-column>
          <el-table-column prop="ssueOrganization" label="驾驶员证发证机构" width="150px"></el-table-column>
          <el-table-column prop="issueDate" label="资格证发证日期" width="150px"></el-table-column>
          <el-table-column prop="getProofDate" label="初次领取资格证日" width="150px"></el-table-column>
          <el-table-column prop="proofOn" label="资格证有效起始日期" width="150px"></el-table-column>
          <el-table-column prop="proofOff" label="资格证有效截止日期" width="150px"></el-table-column>
          <el-table-column prop="proofUrl" label="资格证URL" width="150px"></el-table-column>
          <el-table-column prop="registerDate" label="报备日期" width="150px"></el-table-column>
          <el-table-column prop="contractUrl" label="驾驶员合同" width="150px"></el-table-column>
          <el-table-column prop="contractOn" label="合同有效期起" width="150px"></el-table-column>
          <el-table-column prop="contractOff" label="合同有效期止" width="150px"></el-table-column>
          <el-table-column prop="type" label="驾驶员类型" width="150px">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.type == 1">救护车</el-tag>
              <el-tag v-if="scope.row.type == 2">出租</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" width="150px"></el-table-column>
          <el-table-column prop="orderMoney" label="每次订单费用(元)" width="150px"></el-table-column>
        </el-table>
        <el-row>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20, 100]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-count="pages"
          ></el-pagination>
        </el-row>
      </el-card>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'driverlist',
  components: {},
  data() {
    return {
      area: '',
      options: [],//区域
      props: {
        label: 'province',
        value: 'uuid',
        children: 'children'
      },
      limitList: [],//选择的省市区ID
      corpId: '',//所选机构
      labelPosition: 'right',
      uploadAction: 'http://192.168.123.190/#',
      headers: {
        'content-type': 'multipart/form-data'
      },
      dialogVisible: false,
      productImgs: [],
      imgLimit: 1, //文件个数
      hideUploadEdit: false,
      rightsList: [],
      companyIdlist: [],
      addresslist: [],
      orderCompany: [],// 机构
      pageSize: 10,
      uuid: '',
      photoUrl: '',
      proofUrl: '',
      personId: '',
      personType: '',
      statusOfOnpost: '',
      addDialogFormVisible: false, // 控制添加客户弹出框的显示与隐藏
      editDialogFormVisible: false, // 控制编辑弹出框的显示与隐藏
      formLabelWidth: '200px',
      TransportType: ['下线', '上线'],
      personTypes: ["救护车", "出租"],
      idtype: ["身份证", "其它"],
      addForm: {
        uuid: '',
        driverName: '',
        type: '',
        workNo: '',
        idcNum: '',
        area: '',// 区域
        statusOfOnpost: 1,
        companyId: '',// 机构id
        orderCompanyName: '',// 机构名字------
        remark: ''
      }, // 添加客户弹出框表单
      editForm: {
        uuid: '',
        driverName: '',
        workNo: '',
        idcNum: '',
        statusOfOnpost: '',
        remark: ''
      }, // 编辑弹出框表单
      addFormRules: {
        driverName: [{
          required: true,
          message: '请输入驾驶员名称',
          trigger: 'blur'
        }]
      }, //添加列表校验规则
      editFormRules: {
        driverName: [{
          required: true,
          message: '请输入驾驶员名称',
          trigger: 'blur'
        }]
      }, //编辑列表校验规则
      form: {
        statusOfOnpost: 1
      },
      currentRow: null,
      currentPage: 1,
      pageNo: '',
      pagesize: 10,
      pages: 0,
      total: 0

    }
  },
  mounted() {
    this.select();
    this.getProvince()
  },
  methods: {
    // 选择区域后触发
    ad(v) {
      // console.log(v);
      this.limitList = v
      this.getorderCompany(v)
    },
    // 选择机构后触发
    // dd(v) {
    //   this.addForm.orderCompanyName = v.orderCompanyName
    // },
    handleChange() {
      console.log(this.values);
    },
    // 切换选中表格项时触发
    handleCurrentChange(val) {
      if (!val) return
      // this.Selected = true
      this.editForm = val
      this.allotForm = val
    },
    handleEditChange(file, fileList) {
      let vm = this
      vm.hideUploadEdit = fileList.length >= this.imgLimit
    },
    handleRemove(file, fileList) {
      //移除图片
      console.log(file, fileList);
      this.addForm.photoUrl = ''
      let vm = this
      vm.hideUploadEdit = fileList.length >= this.imgLimit
      this.$refs.upload.clearFiles();
    },
    handlePictureCardPreview(file) {
      //预览图片时调用
      console.log(file);
      this.addForm.photoUrl = file.url;
      this.dialogVisible = true;
    },

    beforeAvatarUpload(file) {
      //文件上传之前调用做一些拦截限制
      console.log(file);
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt8M = file.size / 1024 / 1024 < 8;

      if (!(isJPG || isPNG)) {
        this.$message.error('上传广告图片只能是 JPG/PNG 格式!');
      }
      if (!isLt8M) {
        this.$message.error("上传图片大小不能超过 8MB!");
      }
      return isJPG || isPNG && isLt8M;
    },
    handleAvatarSuccess(res, file) {
      //图片上传成功
      console.log('cg:' + res);
      console.log(file);
      this.imageUrl = URL.createObjectURL(file.raw);
      this.addForm.photoUrl = res.filePath
    },
    handleExceed(files, fileList) {
      //图片上传超过数量限制
      this.$message.error("上传图片不能超过1张!");
      console.log(file, fileList);
    },
    imgUploadError(err, file, fileList) {
      //图片上传失败调用
      console.log(err);
      this.$message.error("上传图片失败!");
    },
    //选中删除
    handleCurrentChange1(val) {
      this.currentRow = val;
      // console.log(this.currentRow.uuid)
      this.uuid = this.currentRow.uuid
      this.editForm = val
      console.log(this.currentRow);
      console.log(this.uuid);
    },
    //查询

    async select() {
      const {
        data: res
      } = await this.$http.post('pmService/tInfoDriver/getTInfoDriverList', {
        "driverName": this.form.driverName,
        "pageNo": this.pageNo,
        "pageSize": this.pageSize,
        statusOfOnpost: 1
      })
      console.log(res);
      this.pages = +res.pageBean.pageCount
      this.total = +res.pageBean.pageDataCount
      this.rightsList = res.data.list
    },


    // 获取省份--调试完成
    async getProvince() {
      const { data: res } = await this.$http.post('userManageServer/area/selectProvinceList', {})
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取省份失败')
      this.options = res.data
      // console.log(res);
      this.options.forEach(async item => {
        item.children = await this.getCity(item.uuid)
        item.children.forEach(async i => {
          i.children = await this.getDistrict(i.uuid)
        })
      })
    },
    // 获取市--调试完成
    async getCity(e) {
      const { data: res } = await this.$http.post('userManageServer/area/selectCityList', {
        "province": e
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取市失败')
      return res.data
    },
    // 获取区域--调试完成
    async getDistrict(e) {
      const { data: res } = await this.$http.post('userManageServer/area/selectDistrictList', {
        "cityId": e
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取区域失败')
      return res.data

    },
    // 按地区获取机构--调试完成
    async getorderCompany(e) {
      const { data: res } = await this.$http.post('userManageServer/tSysCompany/selectCompanyByArea', {
        "cityId": e[1],
        "district": e[2]
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取机构失败')
      console.log(res.data)
      console.log(res.data[0].uuid);
      this.companyIdlist = res.data
    },


    //添加
    async addClient() {
      console.log(this.addForm.companyId);
      const data = await this.$http.post('pmService/tInfoDriver/addTInfoDriver', this.addForm)
      // "driverName":this.addForm.driverName,"companyId":this.addForm.companyId,statusOfOnpost:1}
      console.log(data)
      this.$message({
        showClose: true,
        message: '添加成功！',
        type: 'success'
      });
      this.addDialogFormVisible = false
      // this.$refs.form.resetFields();
      this.select()
    },
    // 监听添加客户对话框的关闭事件
    addDialogClose() {
      this.$refs.addForm.resetFields()
    },
    // 点击编辑按钮触发
    edit() {
      // 判断是否选择编辑对象
      console.log(this.editForm.bindCarNum);
      if (this.editForm.bindCarNum === '') return this.$message.error('请先选择编辑对象')
      // 显示编辑弹出框
      this.editDialogFormVisible = true
    },
    // 编辑客户--确定按钮
    editClient() {
      // 预校验
      this.$refs.editForm.validate(async valid => {
        if (!valid) return
        // 调接口，发送编辑客户信息请求
        const {
          data
        } = await this.$http.post('pmService/tInfoDriver/updateTInfoDriver', this.editForm)
        console.log(data)
        // 判断请求是否成功
        // if (data.status !== '200') return this.$message.error('添加客户失败')
        // 重新渲染数据
        this.select()
        // 关闭弹框
        this.editDialogFormVisible = false
      })
    },
    // 监听编辑客户对话框的关闭事件
    editDialogClose() {
      this.$refs.editForm.resetFields()
    },
    // 删除
    async deletetype() {
      console.log(this.uuid);
      if (this.uuid === '') return this.$message.error('请先选择要删除的行')
      const {
        data
      } = await this.$http.post('pmService/tInfoDriver/deleteTInfoDriver', {
        "uuid": this.uuid
      })
      this.select()
    },
    //  重置表单
    clearBtn() {
      this.$refs.form.resetFields()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    }

  }

}
</script>
<style lang="less" scoped>
.orderStatistics {
  height: 500px;
}
.card-one {
  margin-top: 20px;
  margin-bottom: 30px;
  /deep/ .el-card__body {
    margin-top: 30px;
    margin-left: 40px;
    padding: 0;
  }
  width: 100%;
  padding: 0;
  display: flex;

  .el-form {
    :nth-child(-n + 3).el-form-item {
      margin-right: 39px;
    }
    .el-form-item {
      margin-bottom: 28px;
      height: 32px;
      .el-form-item__content {
        height: 32px;
      }
    }
  }
  .btns {
    display: flex;
    justify-content: left;
    margin-bottom: 16px;
    // .selectBtn,
    // .clearBtn {
    //   height: 34px;
    // }
    .selectBtn {
      margin-right: 14px;
    }
  }
}
.btn-t {
  margin-bottom: 0.3rem;
}
.tab {
  width: 100%;
  .el-table {
    width: 100%;
  }
}
.card-two {
  width: 100%;
  .el-table {
    margin-top: 13px;
    margin-bottom: 22px;
  }
  .el-pagination {
    // margin-right: auto;
    text-align: right;
  }
}
.el-menu {
  width: 409px;
}
</style>
